"use client";

import classNames from "classnames";
import Image from "next/legacy/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useTimeoutFn } from "react-use";
import { Button } from "~/components/ui/Button";
import { useOnboardingDict } from "~/hooks/useTranslations";
import Logo from "~/public/images/logo-horizontal.svg";
import WelcomeBackground from "~/public/images/arena-new.png";
import { getLogInUrl, getSignUpUrl } from "~/utils/url";
import { useSearchParams } from "next/navigation";

export default function WelcomePage() {
  const { t, tc } = useOnboardingDict();
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [returnTo, setReturnTo] = useState("");

  const searchParams = useSearchParams();

  useEffect(() => {
    setReturnTo(searchParams.get("returnTo") ?? "");
  }, [setReturnTo]);

  useTimeoutFn(() => {
    setIsPanelVisible(true);
  }, 1000);

  const clxBg = classNames(
    "absolute",
    "top-0",
    "left-0",
    "w-full",
    "h-full",
    "animate-in",
    "fade-in",
    "duration-100",
  );

  const clxLoginPanel = classNames(
    "fixed",
    "bottom-0",
    "left-0",
    "w-full",
    "h-[359px]",
    "p-4",
    "pt-9",
    "pb-20",
    "bg-white",
    "rounded-t-3xl",
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    "transition-transform",
    "duration-1000",
    {
      "translate-y-full": !isPanelVisible,
      "translate-y-0": isPanelVisible,
    },
  );

  return (
    <>
      <div className={clxBg}>
        <Image
          src={WelcomeBackground}
          layout="fill"
          objectFit="cover"
          alt="Background image"
          priority
        />
      </div>

      <div className={clxLoginPanel}>
        <div style={{ width: 143, height: 35 }}>
          <Logo />
        </div>

        <p className="mb-8 mt-4 px-8 text-body-3">{t("fpWelcomeTitle")}</p>

        <Link href={getSignUpUrl()}>
          <Button
            data-testid="signup-button"
            size="lg"
            className="w-full flex-auto"
          >
            {t("fpSignUp")}
          </Button>
        </Link>

        <p className="mt-4 text-center text-caption-1">
          {t("fpAlreadyHaveAnAccount")}{" "}
          <Link href={getLogInUrl(returnTo)}>
            <Button
              variant="ghost"
              className="flex-auto"
              data-testid="login-button"
            >
              {tc("fpLogIn")}
            </Button>
          </Link>
        </p>
      </div>
    </>
  );
}
